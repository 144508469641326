
import { mapGetters } from "vuex"

export default {
    name: "Login",
    components: {
        AuthForm: () => import("@/components/auth/AuthForm"),
        Modal: () => import("@/components/base/modal/Modal"),
    },
    computed: {
        ...mapGetters("account", ["authDialog", "authRedirect"]),
    },
    methods: {
        onClose() {
            this.$store.dispatch("account/closeAuth")
        },
    },
}
